<template>
  <div class="container height-full">
    <div class="level">
      <div class="level-left">
      </div>
      <div class="level-right">
        <div class="level-item" v-if="checkedRows.length">
          <b-dropdown aria-role="list" position="is-bottom-left">
            <template #trigger="{}">
                <b-button
                    label="Actions"
                    class="has-text-primary"/>
            </template>

            <b-dropdown-item
              aria-role="listitem"
              @click="deleteUsers(checkedRows.map(el => el.userId))">
              Delete
            </b-dropdown-item>
        </b-dropdown>
        </div>
        <div class="level-item">
          <router-link
            tag="button"
            :to="{ name: 'CreateUser' }"
            class="button is-primary">
            Create User
          </router-link>
        </div>
      </div>
    </div>

    <b-table
      :data="users"
      paginated
      :per-page="perPage"
      :current-page.sync="currentPage"
      checkable
      :checked-rows.sync="checkedRows"
      :loading="isLoading">
      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column">
          <template
            #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Search..."
              icon="magnify"/>
          </template>
          <template v-slot="props">
            <div v-if="column.field === 'action'">
              <b-dropdown
                aria-role="list"
                position="is-bottom-left">
                <template #trigger="{}">
                  <a class="has-text-black">
                    <b-icon icon="dots-horizontal"></b-icon>
                  </a>
                </template>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="showChangePrivilege(props.row)">
                  Change privilege
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="deleteUsers([props.row.userId])">Delete</b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-else>
              {{ props.row[column.field] }}
            </div>
          </template>
        </b-table-column>
      </template>
      <template #empty>
        <div class="has-text-centered">No users</div>
      </template>
    </b-table>
    <b-modal
      v-model="isModaleChangePrivilege"
      :width="300"
      @close="closeChangePrivilege">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              Change Privilege
            </p>
          </header>
          <div class="card-content">
            <b-field v-if="currentUser">
              <b-select
                placeholder="Select a profil"
                v-model="currentUser.profil"
                expanded>
                  <option
                    v-for="profil in profils"
                    :value="profil.value"
                    :key="profil.value">
                    {{ profil.label }}
                  </option>
              </b-select>
            </b-field>
          </div>
          <footer class="card-footer">
            <a
              class="card-footer-item has-text-primary"
              @click="closeChangePrivilege">Cancel</a>
            <a
              class="card-footer-item has-background-primary has-text-white"
              @click="actionChangePrivilege">Change</a>
          </footer>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { GetUsers, DeleteMenyUsers, ChangePrivilege } from '@/api/user';
import { PROFILS } from '@/config/user';

export default {
  metaInfo: {
    title: 'Users',
  },
  data() {
    return {
      data: [],
      columns: [
        {
          field: 'name',
          label: 'Name',
          searchable: true,
          sortable: true,
        },
        {
          field: 'email',
          label: 'Date',
          searchable: true,
          sortable: true,
        },
        {
          field: 'mobileNumber',
          label: 'Mobile Number',
          searchable: true,
          sortable: true,
        },
        {
          field: 'profil',
          label: 'Privilege',
          searchable: true,
          sortable: true,
        },
        {
          field: 'action',
          label: '',
        },
      ],
      perPage: 10,
      currentPage: 1,
      isLoading: false,
      isModaleChangePrivilege: false,
      checkedRows: [],
      currentUser: null,
      profils: PROFILS,
    };
  },
  computed: {
    users() {
      return this.data.map(el => ({
        ...el,
        name: `${el.firstName} ${el.lastName}`,
      }));
    },
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true;
        const res = (await GetUsers()).data;
        this.data = res.users;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.showError(error);
      }
    },
    async deleteUsers(ids) {
      const loadingComponent = this.$buefy.loading.open();
      try {
        await DeleteMenyUsers(ids);
        loadingComponent.close();
        this.checkedRows = [];
        await this.getData();
        return '';
      } catch (error) {
        loadingComponent.close();
        this.showError(error);
        return '';
      }
    },
    showChangePrivilege(user) {
      this.currentUser = user;
      this.isModaleChangePrivilege = true;
    },
    closeChangePrivilege() {
      this.currentUser = null;
      this.isModaleChangePrivilege = false;
    },
    async actionChangePrivilege() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        await ChangePrivilege(this.currentUser.userId, this.currentUser.profil);
        this.closeChangePrivilege();
        loadingComponent.close();
        return '';
      } catch (error) {
        loadingComponent.close();
        this.showError(error);
        return '';
      }
    },
  },
  async mounted() {
    await this.getData();
  },
};
</script>

<style>

</style>
